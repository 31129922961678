import { createTheme } from '@mui/material/styles';

export interface AppConfig {
  readonly color: string;
}

export default { color: 'red' } as AppConfig;

export const theme = createTheme({
  palette: {
    primary: {
      main: '#E32E20'
    },
    secondary: {
      main: '#00274A'
    },
    success: {
      main: '#0E9615'
    },
    error: {
      main: '#D32F2F'
    },
    info: {
      main: '#464A4E'
    }
  },

  typography: {
    h1: {
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '1.25rem'
    },
    h3: {
      fontSize: '1rem'
    },
    h4: {
      fontSize: '0.8rem'
    }
  }
});
