import { TourUtils, type DriveBackInformationStatus } from '@/utils/TourUtils';
import type { CompiledTour, EstimatedDrivingTime } from '@badgermoleV2/api';
import type { LatLngExpression } from 'leaflet';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AppStateProps = {
  readonly title: string;
  readonly setTitle: (_: string) => void;
  readonly authExpired: boolean;
  readonly cityPolygons: LatLngExpression[][];
  readonly cityNoParkingAreas: LatLngExpression[][];
  readonly setCityPolygons: (_: LatLngExpression[][]) => void;
  readonly setCityNoParkingAreas: (_: LatLngExpression[][]) => void;
  readonly toggleForbidden: (_: boolean) => void;
  readonly setForbidden: () => void;
  readonly forbidden: boolean;
  readonly setAuthExpired: (_: boolean) => void;

  // Tour
  readonly tour: CompiledTour | undefined;
  readonly setTour: (_: CompiledTour | undefined) => void;
  readonly logout: () => void;
  readonly tourExtensionDeclined: boolean;
  readonly setTourExtensionDeclined: (_: boolean) => void;
  readonly lastBadgermoleErrorMessage: string;
  readonly setLastBadgermoleErrorMessage: (_: string) => void;
  readonly badgermoleErrorOccured: boolean;
  readonly setBadgermoleErrorOccured: (_: boolean) => void;
  readonly PROXIMITY_CHECK_ENABLED: boolean;
  readonly PROXIMITY_THRESHOLD: number;
  readonly driveBackInformationStatusList: DriveBackInformationStatus[];
  readonly setDriveBackInformationStatus: (_: DriveBackInformationStatus) => void;
  readonly setDriveBackInformationStatusList: (_: DriveBackInformationStatus[]) => void;
  readonly estimatedDrivingTime: EstimatedDrivingTime | undefined;
  readonly setEstimatedDrivingTime: (_: EstimatedDrivingTime | undefined) => void;
  readonly fetchedEdt: number | undefined;
  readonly setFetchedEdt: (_: number | undefined) => void;

  readonly relocateMapViewEnabled: boolean;
  readonly setRelocateMapViewEnabled: (_: boolean) => void;

  // For testing in Jira OCXX in production, only enabled when user is part of some ocxx_ group
  readonly OCXXSwitchSettingAllowed: boolean;
  readonly OCXXSwitchAllowedByUserGroup: boolean;
  readonly OCXXEnabled: boolean;
  readonly VEHICLE_REPORT_SEARCH_DELAY: number;
  readonly setOCXXSwitchAllowedByUserGroup: (_: boolean) => void;
  readonly setOCXXEnabled: (_: boolean) => void;

  readonly completedStepsForJob: string[];
  readonly setCompletedStepsForJob: (_: string[]) => void;

  // OIDC AUTHd
  readonly oidcCode: string | null;
  readonly setOidcCode: (_: string) => void;
};

const initialState = {
  title: 'emmy Service',
  forbidden: false,
  authExpired: false,
  tour: undefined,
  cityPolygons: [] as LatLngExpression[][],
  cityNoParkingAreas: [] as LatLngExpression[][],
  tourExtensionDeclined: false,
  relocateMapViewEnabled: false,
  lastBadgermoleErrorMessage: '',
  badgermoleErrorOccured: false,
  PROXIMITY_CHECK_ENABLED: import.meta.env.VITE_PROXIMITY_CHECK_ENABLED! === 'true',
  PROXIMITY_THRESHOLD: +import.meta.env.VITE_PROXIMITY_THRESHOLD!,
  driveBackInformationStatusList: TourUtils.driveBackInformation,
  estimatedDrivingTime: undefined,
  fetchedEdt: undefined,

  // For testing in Jira OCXX in production, only enabled when user is part of some ocxx_ group
  OCXXSwitchSettingAllowed: import.meta.env.VITE_OCXX_OPTION_ALLOWED! === 'true',
  OCXXSwitchAllowedByUserGroup: false,
  OCXXEnabled: false,
  VEHICLE_REPORT_SEARCH_DELAY: +import.meta.env.VITE_VEHICLE_REPORT_SEARCH_DELAY!,
  completedStepsForJob: [],
  oidcCode: null
};

/* The `persist` function is a middleware that allows to persist the state of the store. */
export const useStore = create<AppStateProps>()(
  persist(
    //get is necessary to retrieve the values, but eslint complains that it is unused
    // eslint-disable-next-line
    (set, get) => ({
      ...initialState,
      setAuthExpired: (authExpired) => set({ authExpired }),
      setCityPolygons: (cityPolygons) => set({ cityPolygons }),
      setCityNoParkingAreas: (cityNoParkingAreas) => set({ cityNoParkingAreas }),
      setTitle: (title) => set({ title }),
      toggleForbidden: (forbidden) => set({ forbidden }),
      setForbidden: () => set({ forbidden: true }),
      setTour: (tour) => set({ tour }),
      setDriveBackInformationStatus: (driveBackInfromationStatus: DriveBackInformationStatus) =>
        set((state) => ({
          driveBackInformationStatusList: state.driveBackInformationStatusList.map((info) =>
            info.id === driveBackInfromationStatus.id ? driveBackInfromationStatus : info
          )
        })),
      setDriveBackInformationStatusList: (driveBackInformationStatusList) =>
        set({ driveBackInformationStatusList }),
      setEstimatedDrivingTime: (estimatedDrivingTime) => set({ estimatedDrivingTime }),
      setFetchedEdt: (fetchedEdt) => set({ fetchedEdt }),
      logout: () => set({ ...initialState }),
      setTourExtensionDeclined: (declined) => set({ tourExtensionDeclined: declined }),
      setOCXXSwitchAllowedByUserGroup: (allowed) => set({ OCXXSwitchAllowedByUserGroup: allowed }),
      setOCXXEnabled: (enabled) => set({ OCXXEnabled: enabled }),
      setLastBadgermoleErrorMessage: (lastBadgermoleErrorMessage) =>
        set({ lastBadgermoleErrorMessage }),
      setBadgermoleErrorOccured: (badgermoleErrorOccured) => set({ badgermoleErrorOccured }),
      setCompletedStepsForJob: (steps: string[]) => set({ completedStepsForJob: steps }),
      setRelocateMapViewEnabled: (relocateMapViewEnabled: boolean) =>
        set({ relocateMapViewEnabled }),
      setOidcCode: (oidcCode) => set({ oidcCode })
    }),

    {
      name: 'app-store'
    }
  )
);
