import { Chip, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

type DrawerSelectionItemProps = {
  title: string;
  disabled?: boolean;
  running?: boolean;
  onClick: () => void;
  dataTestId?: string;
  IconComponent: React.ReactNode;
};

export const DrawerSelectionItem = ({
  title,
  disabled,
  running,
  onClick,
  dataTestId,
  IconComponent
}: DrawerSelectionItemProps) => {
  const { t } = useTranslation();
  return (
    <ListItemButton disabled={disabled} onClick={onClick} divider data-testid={dataTestId}>
      <ListItemIcon>{IconComponent}</ListItemIcon>
      <ListItemText
        primary={title}
        secondary={
          running && (
            <Chip
              label={t('common:labels:open')}
              color={'primary'}
              size={'small'}
              data-testid={dataTestId + 'Running'}
            />
          )
        }
      />
    </ListItemButton>
  );
};
