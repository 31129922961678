import { QUERY_KEYS } from '@/config/QueryKeys';
import { useStore } from '@/store';
import { MapUtils } from '@/utils/MapUtils';
import type { BusinessLocation } from '@badgermoleV2/api';
import { useQuery } from '@tanstack/react-query';
import type { LatLngExpression } from 'leaflet';
import { latLng } from 'leaflet';
import { useCallback, useEffect } from 'react';
import { useUserLocation } from './useUserLocation';
import { getWunderBusinessLocations } from '@badgermoleV2/public';

export const useCityBounds = (isOnlyExternal: boolean) => {
  const [setCityPolygons, setCityNoParkingAreas] = useStore((s) => [
    s.setCityPolygons,
    s.setCityNoParkingAreas
  ]);

  const { location } = useUserLocation('current');

  const determineCurrentBusinessLocation = useCallback(
    (userLat: number, userLon: number, businessLocations: BusinessLocation[]) => {
      const userLocation = latLng(userLat, userLon);
      const distanceToCenters = businessLocations.map((businessLocation) => {
        const cityCenterLatLng = latLng(
          businessLocation.cityCenter.lat,
          businessLocation.cityCenter.lon
        );
        return [businessLocation.label, userLocation.distanceTo(cityCenterLatLng)];
      });
      const nearestCityCenter = distanceToCenters.reduce((acc, loc) =>
        acc[1] < loc[1] ? acc : loc
      );
      return businessLocations.find((location) => location.label === nearestCityCenter[0]);
    },
    []
  );

  const generateCityPolygons = useCallback(
    (currentLocation: BusinessLocation) => {
      const cityPols = [] as LatLngExpression[][];
      const noParkingAreas = [] as LatLngExpression[][];

      if (currentLocation) {
        (currentLocation.businessTerritory.polygons ?? []).forEach((polygon) =>
          cityPols.push(MapUtils.generatePolylinePositions(polygon.coordinates ?? []))
        );

        (currentLocation.noParkingTerritories ?? []).forEach((noParkingTerritory) =>
          (noParkingTerritory.polygons ?? []).forEach((polygon) =>
            noParkingAreas.push(MapUtils.generatePolylinePositions(polygon.coordinates ?? []))
          )
        );
      }

      setCityPolygons(cityPols);
      setCityNoParkingAreas(noParkingAreas);
    },
    [setCityNoParkingAreas, setCityPolygons]
  );

  const updateCity = useCallback(
    (data: BusinessLocation[]) => {
      const nearestLocation = determineCurrentBusinessLocation(
        location!.coords.latitude,
        location!.coords.longitude,
        data
      );
      generateCityPolygons(nearestLocation!);
    },
    [determineCurrentBusinessLocation, generateCityPolygons, location]
  );

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.locations.getBusinessLocation, location?.coords, isOnlyExternal],
    enabled: location !== undefined && !isOnlyExternal,
    queryFn: async ({ signal }) => getWunderBusinessLocations({ signal }),
    staleTime: 1000 * 60 * 60 // 1hr
  });

  useEffect(() => {
    if (data) updateCity(data);
  }, [data, updateCity]);

  return;
};
