import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter, TourHeader } from '@/components/Tour';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useServiceTour } from '@/hooks/Tour/Service/useServiceTour';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useUserLocation } from '@/hooks/useUserLocation';
import { useStore } from '@/store';
import { TourUtils } from '@/utils/TourUtils';
import type { CompiledTour } from '@badgermoleV2/api';
import { AssignmentState, AssignmentType, MissionType } from '@badgermoleV2/api';
import { Alert, Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type ExtendTourScreenProps = {
  missionType: MissionType;
};

export const ExtendTourScreen = ({ missionType }: ExtendTourScreenProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { location, locationError } = useUserLocation('watch');
  const setTourExtensionDeclined = useStore((s) => s.setTourExtensionDeclined);

  const { startNextConsideredStepAsync, isStartNextConsideredStepPending, isStartStepPending } =
    useStepInteraction();
  const { reloadTour, extendTour, isExtendTourPending, isReloadTourPending } = useTourInteraction();
  const { startNextStepOrJobAsync, isNextStepOrJobPending } = useServiceTour();

  const { openTour: compiledTour } = useOpenTour(missionType);

  const handleNextStepStartAfterTourUpdate = async (updatedTour: CompiledTour) => {
    if (updatedTour.tour.tourType === MissionType.Pickup) {
      await startNextConsideredStepAsync(updatedTour);
      navigate(getNextScreenLocation());
    } else {
      await startNextStepOrJobAsync(updatedTour);
      navigate(getNextScreenLocation());
    }
  };

  const declineTourExtension = async () => {
    // Reloading tour because it could have change due to a tour edit
    setTourExtensionDeclined(true);
    if (!compiledTour) return;
    await reloadTour(compiledTour.tour.tourType);
    navigate(getNextScreenLocation());
  };

  const extendTourAction = async () => {
    await reloadTour(compiledTour!.tour.tourType);
    if (!compiledTour) return;
    // Reloading tour because it could have change due to a tour edit
    const consideredJobs = (compiledTour.tour.jobs ?? []).filter(
      (j) =>
        (j.state === AssignmentState.Considered || j.state === AssignmentState.InProgress) &&
        j.jobType !== AssignmentType.DriveBack
    );
    if (consideredJobs.length > 0) {
      handleNextStepStartAfterTourUpdate(compiledTour);
    } else {
      if (!location) return;
      const updatedTour = await extendTour({
        compiledTour,
        location
      });
      handleNextStepStartAfterTourUpdate(updatedTour);
    }
  };

  const handleForceReloadTour = (): void => {
    if (!compiledTour) return;
    reloadTour(compiledTour.tour.tourType);
  };

  const batteriesLeft = !compiledTour ? 0 : TourUtils.getBatteriesLeft(compiledTour);

  const getNextScreenLocation = () => {
    if (!compiledTour) {
      window.scrollTo(0, 0);
      return `${ROUTES.DEFAULT}`;
    }
    if (
      compiledTour.tour.tourType === MissionType.Swap ||
      compiledTour.tour.tourType === MissionType.Custom
    ) {
      window.scrollTo(0, 0);
      return `${compiledTour.tour.tourType === MissionType.Swap ? ROUTES.SERVICE.ROOT : ROUTES.CUSTOM.ROOT}${ROUTES.TOUR.RUN}`;
    } else {
      window.scrollTo(0, 0);
      return `${ROUTES.PICKUP.ROOT}${ROUTES.TOUR.RUN}`;
    }
  };

  const loading =
    isStartNextConsideredStepPending ||
    isStartStepPending ||
    isExtendTourPending ||
    isNextStepOrJobPending;

  if (!compiledTour) return <DataLoadingDialog open={true} message={t('common:loading:general')} />;

  return (
    <>
      {isReloadTourPending && (
        <DataLoadingDialog open={true} message={t('common:loading:general')} />
      )}
      <TourHeader tour={compiledTour!} title={undefined} isTourRunning cancelAction={undefined} />
      <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '2rem 1.5rem 10rem 1.5rem' }}>
        <Card sx={{ padding: 1 }}>
          <CardContent>
            <Typography color="text.secondary">
              {compiledTour!.tour.tourType === MissionType.Pickup
                ? t('pickup-tour:extendHintTop')
                : t('service-tour:extendList.extendHintTop')}
            </Typography>
            {compiledTour!.tour.tourType === MissionType.Swap && (
              <Typography
                color="text.secondary"
                gutterBottom
                sx={{ fontSize: '18px', fontWeight: '600', marginTop: '0.25rem' }}
              >
                {`${t('service-tour:extendList.batteriesLeft')} ${batteriesLeft}`}
              </Typography>
            )}
            <Typography variant={'h5'} component="div">
              {compiledTour!.tour.tourType === MissionType.Pickup
                ? t('pickup-tour:extend')
                : t('service-tour:extendList.extend')}
            </Typography>
            <Typography color="text.secondary">
              {compiledTour!.tour.tourType === MissionType.Pickup
                ? t('pickup-tour:extendHintBottom')
                : t('service-tour:extendList.extendHintBottom')}
            </Typography>
            {loading && <DataLoadingDialog open={true} message={t('common:loading:general')} />}
          </CardContent>
          <CardActions>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <Button
                aria-label={'yes'}
                variant={'outlined'}
                color={'secondary'}
                disabled={loading || !location}
                onClick={() => extendTourAction()}
                data-testid={'acceptTourExtension'}
                sx={{ fontSize: '12px' }}
              >
                {t('pickup-tour:extendAccept')}
              </Button>
              <Button
                aria-label={'no'}
                variant={'outlined'}
                color={'secondary'}
                disabled={loading}
                onClick={declineTourExtension}
                data-testid={'declineTourExtension'}
                sx={{ fontSize: '12px' }}
              >
                {t('pickup-tour:extendDecline')}
              </Button>
            </Box>
          </CardActions>
          {!location && !locationError && (
            <DataLoadingDialog open={true} message={t('common:loading:general')} />
          )}
          {locationError && (
            <Box sx={{ margin: 1, padding: 1 }}>
              <Alert severity={'error'}>{t('common:geolocation:unavailable')}</Alert>
            </Box>
          )}
        </Card>
      </Box>
      <TourFooter reloadAction={handleForceReloadTour} />
    </>
  );
};
