import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { TourFooter } from '@/components/Tour';
import { useVehicleTypes } from '@/hooks/Cache/useVehicleTypes';
import { useMissions } from '@/hooks/Mission/useMissions';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useTourParameterOptionsBundle } from '@/hooks/Tour/General/useTourParameterOptionsBundle';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { useTitle } from '@/hooks/useTitle';
import { BeforeYouGoScreen } from '@/screens/TourScreens/General/BeforeYouGoScreen';
import { TourUtils } from '@/utils/TourUtils';
import type { TourParametersBody } from '@badgermoleV2/api';
import { MissionType } from '@badgermoleV2/api';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { TourParameterSelection } from './TourParameterSelection';

interface PrepareTourScreenProps {
  readonly missionType: MissionType;
}

export const PrepareTourScreen = ({ missionType }: PrepareTourScreenProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [serviceTourRequested, setServiceTourRequested] = useState(false);

  const { pickupMission, serviceMission, isLoadingMissions } = useMissions({
    disablePickup: missionType !== MissionType.Pickup,
    disableService: missionType !== MissionType.Swap,
    disableRelocate: true,
    disableCustom: true,
    disableOpenTours: true
  });

  const { refetch: refetchOpenTour } = useOpenTour(missionType);

  const { startTourAsync, isStartTourPending, isStartTourError } = useTourInteraction();
  const { startStepAsync, isStartStepPending, isStartStepSuccess } = useStepInteraction();

  const { bundle, isBundleFetching } = useTourParameterOptionsBundle(missionType);

  const { vehicleTypes } = useVehicleTypes();

  const nextMission = missionType === MissionType.Pickup ? pickupMission : serviceMission;

  const [capacity, setCapacity] = useState(0);

  const title =
    missionType === MissionType.Pickup
      ? 'pickup-tour:prepare'
      : !serviceTourRequested
        ? 'service-tour:prepare'
        : 'service-tour:beforeYouGoList.title';
  useTitle({ title });

  const startServiceTour = async (tourBody: TourParametersBody) => {
    setServiceTourRequested(true);
    window.scrollTo(0, 0);
    setCapacity(tourBody.capacity);
    await startTourAsync(tourBody);
  };

  const startPickupTour = async (tourBody: TourParametersBody) => {
    const tour = await startTourAsync(tourBody);

    if (!tour) {
      window.scrollTo(0, 0);
      return;
    }
    const driveToStep = TourUtils.getFirstDriveToStep(tour);
    await startStepAsync({ tour, step: driveToStep });
  };

  const startRequestTour = async (tourBody: TourParametersBody) => {
    if (missionType === MissionType.Swap) {
      startServiceTour(tourBody);
    } else {
      startPickupTour(tourBody);
    }
  };

  useEffect(() => {
    if (isStartTourError) setServiceTourRequested(false);
  }, [isStartTourError]);

  if (isStartStepSuccess) {
    navigate(
      `${TourUtils.chooseTourTypeOutput(
        missionType,
        ROUTES.PICKUP.ROOT,
        ROUTES.SERVICE.ROOT,
        ROUTES.RELOCATE
      )}${ROUTES.TOUR.RUN}`
    );
    window.scrollTo(0, 0);
  }

  if (isLoadingMissions) {
    return <DataLoadingDialog open={true} message={t('common:loading:general')} />;
  }

  if (!nextMission) {
    toast.error('No missions available!');
    navigate(ROUTES.OVERVIEW);
    return <></>;
  }

  if (serviceTourRequested)
    return <BeforeYouGoScreen requestTourLoading={isStartTourPending} batteryCapacity={capacity} />;

  if (isBundleFetching || !bundle)
    return <DataLoadingDialog open={true} message={t('common:loading:general')} />;

  return (
    <>
      {(isStartStepPending || isStartTourPending) && (
        <DataLoadingDialog open={true} message={t('common:loading:tourGenerate')} />
      )}
      <TourParameterSelection
        bundle={bundle}
        vehicleTypes={vehicleTypes}
        mission={nextMission}
        onStart={startRequestTour}
      />
      <TourFooter
        reloadAction={() => {
          refetchOpenTour();
        }}
      />
    </>
  );
};
