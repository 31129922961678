import { CancelStepDialog } from '@/components/Dialogs/CancelStepDialog';
import { CompleteChecksDialog } from '@/components/Dialogs/CompleteChecksDialog';
import { ActionTaskList, SwapTaskList } from '@/components/Tour/Job/CurrentJob/SwapTasks';
import { VehicleInteractions } from '@/components/Tour/Step/VehicleInteractions';
import { ReportButton } from '@/components/common/ReportButton/ReportButton';
import { useStore } from '@/store';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import { TourUtils } from '@/utils/TourUtils';
import type {
  CompleteChecksResponse,
  Job,
  LocalizedDescription,
  MissionType,
  Step,
  TransitionAction
} from '@badgermoleV2/api';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import CachedIcon from '@mui/icons-material/Cached';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Box, Button, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReplaceHelmetTask } from './SwapTasks/ReplaceHelmetTask';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';

type CurrentJobProps = {
  readonly missionType: MissionType;
  readonly job: Job | undefined;
  readonly mainSteps: Step[];
  readonly replaceHelmetStep: Step | undefined;
  readonly loading: boolean;
  readonly completeActionDisabled: boolean;
  readonly onLastStepComplete: (_: Step) => Promise<void>;
  readonly onLastStepFail: (_: Step, cancelAction: TransitionAction, reason: string) => void;
  readonly onStepComplete: (_: Step) => void;
  readonly onStepCompleteWithResolution: (_: Step, resolution: string | undefined) => void;
  readonly onStepFail: (_: Step, cancelAction: TransitionAction, reason: string) => void;
  readonly showTelematicCheckDialog: boolean;
  readonly telematicCheckResponse: CompleteChecksResponse | undefined;
  readonly onCloseTelematicDialog: () => void;
  readonly handleStepRefresh: (_: Step) => void;
};

export const CurrentJob = ({
  missionType,
  job,
  mainSteps,
  replaceHelmetStep,
  loading,
  onLastStepComplete,
  onLastStepFail,
  onStepComplete,
  onStepCompleteWithResolution,
  onStepFail,
  showTelematicCheckDialog,
  telematicCheckResponse,
  onCloseTelematicDialog,
  handleStepRefresh
}: CurrentJobProps) => {
  const { t, i18n } = useTranslation();

  const { openTour: compiledTour } = useOpenTour(missionType);

  const [selectedTasksInLocalStorage, setSelectedTasksInLocalStorage] = useStore((s) => [
    s.completedStepsForJob,
    s.setCompletedStepsForJob
  ]);

  const [selectedStandard, setSelectedStandard] = useState(
    selectedTasksInLocalStorage.filter((step) => TourUtils.standardTasks.includes(step))
  );
  const [selectedSecurity, setSelectedSecurity] = useState(
    selectedTasksInLocalStorage.filter((step) => TourUtils.securityTasks.includes(step))
  );

  const lastStep = job!.postSteps![0];

  const [showFailJobDialog, setShowFailJobDialog] = useState(false);

  const pendingSteps = useMemo(() => {
    const standardPending = selectedStandard.length !== TourUtils.standardTasks.length;
    const securityPending = selectedSecurity.length !== TourUtils.securityTasks.length;
    const mainStepsPending = mainSteps.filter((s) => !s.isDoneOrFailed).length > 0;
    const replaceHelmetsPending = replaceHelmetStep ? !replaceHelmetStep.isDoneOrFailed : false;
    return standardPending || securityPending || mainStepsPending || replaceHelmetsPending;
  }, [mainSteps, replaceHelmetStep, selectedStandard, selectedSecurity]);

  useEffect(() => {
    setSelectedTasksInLocalStorage(selectedStandard.concat(selectedSecurity));
  }, [selectedStandard, selectedSecurity, setSelectedTasksInLocalStorage]);

  return (
    <Paper sx={{ margin: '.5rem', padding: '.75rem', background: '#e5e5e5', marginBottom: '2rem' }}>
      <Typography
        fontSize="x-large"
        fontWeight="bold"
        sx={{ marginBottom: 1, textAlign: 'center' }}
      >
        {job!.summary[i18n.language as keyof LocalizedDescription]}
        <IconButton
          size="small"
          onClick={() => {
            handleStepRefresh(job!.preSteps![0]);
          }}
          data-testid="stepRefresh"
        >
          <CachedIcon />
        </IconButton>
      </Typography>
      {job && telematicCheckResponse && showTelematicCheckDialog && (
        <CompleteChecksDialog
          allowIgnore={false}
          onIgnore={undefined}
          open={showTelematicCheckDialog}
          tour={compiledTour!}
          job={job}
          step={lastStep}
          completeChecksResponse={telematicCheckResponse}
          onRetry={async () => await onLastStepComplete(lastStep)}
          onClose={() => onCloseTelematicDialog()}
        />
      )}

      <CancelStepDialog
        open={showFailJobDialog}
        handleOk={(cancelAction, reason) => {
          setShowFailJobDialog(false);
          onLastStepFail(lastStep, cancelAction, reason);
          setSelectedTasksInLocalStorage([]);
        }}
        handleCancel={() => {
          setShowFailJobDialog(false);
        }}
        cancelAction={lastStep.cancelActions![0]}
      />

      <VehicleInteractions tour={compiledTour!.tour} disableAllButtons={false} step={lastStep} />

      <Stack spacing={2} sx={{ marginTop: 1 }}>
        <Box>
          <Divider sx={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: 1 }}>
            {t('service-tour:mainTasks')}
          </Divider>
          <ActionTaskList
            steps={mainSteps}
            onStepComplete={onStepComplete}
            onStepFail={onStepFail}
          />
        </Box>

        {replaceHelmetStep && (
          <ReplaceHelmetTask
            step={replaceHelmetStep}
            onStepComplete={onStepCompleteWithResolution}
          />
        )}

        <SwapTaskList
          heading={t('service-tour:standardAndSecurityTasks.standardHeader')}
          steps={TourUtils.standardTasks}
          selected={selectedStandard}
          setSelected={setSelectedStandard}
          showSelectAllButton
        />
        <SwapTaskList
          heading={t('service-tour:standardAndSecurityTasks.securityHeader')}
          steps={TourUtils.securityTasks}
          selected={selectedSecurity}
          setSelected={setSelectedSecurity}
          showSelectAllButton
        />

        <Box>
          <Divider
            sx={{
              fontWeight: 'bold',
              fontSize: '1em',
              marginBottom: 1
            }}
          >
            {t('pickup-tour:problemOptions')}
          </Divider>
          <Button
            color="error"
            size="small"
            data-testid={'failActionServiceFailed'}
            disabled={loading}
            variant="outlined"
            onClick={() => {
              setShowFailJobDialog(true);
            }}
          >
            {t('service-tour:failed')}
          </Button>
        </Box>

        {lastStep.vehicle && lastStep.cancelActions && lastStep.cancelActions.length !== 0 && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between',
              marginTop: '.5rem',
              marginLeft: '.5rem'
            }}
          >
            {/* We need this hidden Box for automated tests, which will expose the raw licence plate */}
            <Box data-testid={'licencePlateValue'} sx={{ display: 'none' }}>
              {lastStep.vehicle.licencePlate}
            </Box>
            <ReportButton vehicle={lastStep.vehicle} />
            <Button
              variant="text"
              color="secondary"
              size="small"
              onClick={() => {
                handleStepRefresh(job!.preSteps![0]);
              }}
            >
              {'GPS ' + t('common:buttons.refresh')}
              <GpsFixedIcon fontSize="small" sx={{ marginLeft: '.25rem' }} />
            </Button>
            <Button
              disabled={loading}
              variant={'text'}
              size={'small'}
              color={'secondary'}
              onClick={() => {
                window.open(NavigatorUtils.createMapsUrl(job?.location), '_blank');
              }}
            >
              {t('common:buttons.navigate')}
              <AssistantDirectionIcon fontSize="small" sx={{ marginLeft: '.25em' }} />
            </Button>
          </Box>
        )}

        <Divider />

        <Button
          data-testid={'completeActionServiceFinished'}
          fullWidth
          color={'success'}
          disabled={loading || pendingSteps}
          variant="contained"
          onClick={async () => {
            await onLastStepComplete(lastStep);
            setSelectedTasksInLocalStorage([]);
          }}
        >
          {t('service-tour:finished')}
        </Button>
      </Stack>
    </Paper>
  );
};
