import { Button, DialogActions, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerInfinity } from 'spinners-react';

type DataLoadingDialogProps = {
  readonly open: boolean;
  readonly message: string;
};

const waitTimeSeconds = 32; // = timeout of the AWS Lambda function that forwards the request

export const DataLoadingDialog = ({ open, message }: DataLoadingDialogProps) => {
  const [t] = useTranslation();
  const [firstShownAt] = useState(new Date());
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [forceClosed, setForceClosed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diffSeconds = (now.getTime() - firstShownAt.getTime()) / 1000.0;
      if (diffSeconds > waitTimeSeconds) {
        setShowCloseButton(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearTimeout(interval);
    };
  }, [firstShownAt]);

  const forceCloseDialog = async () => {
    setForceClosed(true);
    window.location.reload();
  };

  return (
    <Dialog open={forceClosed ? false : open} onClose={() => {}}>
      <DialogTitle sx={{ textAlign: 'center' }}>{message}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
          <SpinnerInfinity
            style={{ margin: '.5rem' }}
            size={60}
            thickness={120}
            speed={100}
            color="#E32E20"
            secondaryColor="black"
            data-testid="dataLoading"
          />
          <Typography sx={{ fontWeight: 'bold' }}>{t('common:loading:doNotClose')}</Typography>
          <Typography>{t('common:loading:doNotNavigate')}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        {showCloseButton && <Button onClick={forceCloseDialog}>{t('common:buttons:close')}</Button>}
      </DialogActions>
    </Dialog>
  );
};
