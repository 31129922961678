import { Collapse, Divider, List } from '@mui/material';
import type { CompiledTour, Job, Vehicle } from '@badgermoleV2/api';
import { RelocateVehicle } from '@/screens/TourScreens/Relocate/RelocateVehicle';
import React from 'react';

type ActiveRelocateVehiclesProps = {
  readonly compiledTour: CompiledTour;
  readonly vehicles: Job[];
  readonly open?: boolean;
  readonly removeVehicleFromTour: (vehicle: Vehicle) => void;
};

export const ActiveRelocateVehicles = ({
  compiledTour,
  vehicles,
  open,
  removeVehicleFromTour
}: ActiveRelocateVehiclesProps) => {
  return (
    <List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {vehicles.map((job) => (
          <React.Fragment key={`RelocateVehicle${job.jobId}`}>
            <RelocateVehicle
              job={job}
              compiledTour={compiledTour}
              vehicle={job.atSteps![0].vehicle!}
              step={job.atSteps![0]}
              removeVehicleFromTour={removeVehicleFromTour}
            />
            <Divider />
          </React.Fragment>
        ))}
      </Collapse>
    </List>
  );
};
