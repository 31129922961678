// These are absolutely not necessary but it is nice to have named mutations in dev tool

export const MUTATION_KEYS = {
  tour: {
    request: 'request-tour',
    modify: 'adapt-tour',
    reload: 'reload-tour',
    extend: 'extend-tour',
    fail: 'fail-tour',
    complete: 'complete-tour',
    forceFail: 'force-fail-tour'
  },
  step: {
    batchStart: 'batch-start-steps',
    start: 'start-step',
    fail: 'fail-step',
    complete: 'complete-step',
    reload: 'reload-position-step',
    check: 'check-complete-step'
  },
  alarm: {
    enable: 'enable-vehicle-alarm',
    disable: 'disable-vehicle-alarm'
  }
};
