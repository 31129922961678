import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    // path to translation files
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
    // load languages at app start
    // pass on multiple version checks of one language
    // e.g. found en -> no lookup for en-US
    supportedLngs: ['de', 'en'],
    // name of json files
    ns: [
      'common',
      'overview',
      'umparken',
      'report-vehicle',
      'connect-to-jira',
      'pickup-tour',
      'service-tour',
      'custom-tour',
      'relocate',
      'error',
      'external-workshop'
    ],
    fallbackLng: ['en', 'de'],
    debug: false
  });

export default i18n;
