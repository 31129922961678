import { QUERY_KEYS } from '@/config/QueryKeys';
import { useUserLocation } from '@/hooks/useUserLocation';
import type { ExtendedBase64Image } from '@/utils/ReportUtils';
import type {
  Base64Image,
  VehicleReportBody,
  VehicleReportBodyIncidentsEnum
} from '@badgermoleV2/api';
import { createVehicleReport, getVehiclesSearch } from '@badgermoleV2/vehicles';
import { useMutation, useQuery } from '@tanstack/react-query';
import Compress from 'compress.js';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const maximumImageSizeMb = 8;

export const useVehicleReport = (
  licencePlate: string,
  description: string,
  images: ExtendedBase64Image[],
  incidents: VehicleReportBodyIncidentsEnum[]
) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [processingImages, setProcessingImages] = useState(false);
  const { location, locationPermission } = useUserLocation('watch');

  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.byLicencePlate, licencePlate],
    queryFn: async ({ signal }) =>
      await getVehiclesSearch(
        searchParams.has('vehicle') ? searchParams.get('vehicle')! : licencePlate,
        { signal }
      ),
    enabled: searchParams.has('vehicle') || licencePlate.length >= 3
  });

  const compressFileList = async (files: FileList) => {
    setProcessingImages(true);
    const maxImageLength = images.length + files.length <= 12;
    if (files && maxImageLength) {
      const fileArray: File[] = Array.from(files!);
      const compress = new Compress();

      const compressedImages = await compress.compress(fileArray, {
        size: 4,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true
      });

      const filesAndCompressedImages = compressedImages.map(function (e, i) {
        return {
          compressResult: e,
          file: fileArray[i]
        };
      });

      const extendedBase64Images = await Promise.all(
        filesAndCompressedImages.map(async function (tuple) {
          const identifier = URL.createObjectURL(tuple.file);
          const imageType = tuple.compressResult.ext.split('/')[1];
          const base64Data = tuple.compressResult.data;

          const image = {
            imageType: imageType,
            base64EncodedImage: base64Data
          } as Base64Image;

          return {
            identifier: identifier,
            image: image,
            sizeInMb: tuple.compressResult.endSizeInMb
          } as ExtendedBase64Image;
        })
      );
      setProcessingImages(false);
      return extendedBase64Images;
    }
    setProcessingImages(false);
    return undefined;
  };

  const { mutate: send, isPending } = useMutation({
    mutationFn: async (body: VehicleReportBody) => await createVehicleReport(body),
    onSuccess: () => toast.success(t('report-vehicle:success'))
  });

  const isCompleteReportBody =
    incidents?.length !== 0 && (images?.length !== 0 || description?.length !== 0);

  const totalImageSizeReached =
    images.map((x) => x.sizeInMb).reduce((prev, curr) => prev + curr, 0) > maximumImageSizeMb;

  const disableSend =
    isPending || totalImageSizeReached || !locationPermission || !isCompleteReportBody;

  return {
    vehicleList: data,
    isSending: isPending,
    disableSend,
    send,
    totalImageSizeReached,
    isLoadingVehicle: isFetching,
    compressFileList,
    processingImages,
    location
  };
};
